@import "./../../style/colors";
@import "./../../style/typography";
@import "./../../style/variables";

.superCard {
  margin-top: 11rem;
  width: 100%;
  padding: 0 $spacer-05;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: left;
    @include colors;
    @include subtitle(true, var(--roasthof-blue));
  }

  .superCard-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    height: 100%;
  }

  .superCard-text {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    @include body;
  }

  .box {
    display: flex;
    padding: 0 $spacer-2;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    width: 80%;

    div {
      width: 50%;
      ul li {
        line-height: 30px;
      }

      h2 {
        margin-left: 2rem;
      }
    }
  }

  .blue-rect {
    @include colors;
    width: $spacer-05;
    height: 8rem;
    background-color: var(--roasthof-blue);
    border-radius: 15px;
    align-self: flex-end;
    margin: 0 $spacer-1;
  }

  .black-rect {
    @include colors;
    width: $spacer-05;
    height: 8rem;
    background-color: var(--roasthof-black);
    border-radius: 15px;
    align-self: flex-start;
    margin: 0 $spacer-1;
  }

  .reverse {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 $spacer-2;
    margin-top: 5rem;
  }

  .box-img {
    width: 40%;
    border-radius: 15px;
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}

.superCard-mobile {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: left;
    @include colors;
    @include subtitle(true, var(--roasthof-blue));
  }

  .superCard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  iframe {
    width: 70%;
    height: 13rem;
  }

  .box-img {
    width: 100%;
    border-radius: 15px;
  }

  .superCard-text {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    @include body;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    width: 80%;

    div {
      ul {
        padding: 0;
        li {
          line-height: 30px;
        }
      }

      h2 {
        margin-left: 2rem;
      }
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}
