@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./../../style/colors";
@import "./../../style/typography";
@import "./../../style/variables";

.apartment-container {
  @include colors;
  margin-top: 10rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    @include subtitle(true, var(--roasthof-blue));
  }

  .apartment-subtitle {
    @include subtitle(true, var(--roasthof-heavy-gray));
  }

  .apartment-description {
    width: 80%;
    text-align: left;
    line-height: 30px;
    margin-bottom: 3rem;
  }

  .image-gallery {
    width: 30rem;
    padding: 0 $spacer-1;
  }

  .cover-img {
    height: 20rem;
    width: 20rem;
  }

  .image-gallery-image {
    width: 100%;
    height: 20rem;
  }

  .image-gallery-bullets .image-gallery-bullet.active {
    background-color: var(--roasthof-blue);
    border: none;
  }

  .image-gallery-icon {
    color: var(--roasthof-blue);
  }

  .image-gallery-right-nav .image-gallery-svg,
  .image-gallery-left-nav .image-gallery-svg {
    height: 4rem;
    width: 2rem;
  }

  .image-gallery-slide-wrapper,
  .fullscreen,
  .image-gallery-swipe,
  .image-gallery-slides,
  .image-gallery-center,
  .image-gallery-center img {
    height: 100%;
  }

  .image-gallery-bullets .image-gallery-bullet {
    box-shadow: none;
    background-color: var(--roasthof-heavy-gray);
  }

  .apartment {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .services-cross {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 $spacer-1;
    margin-bottom: 5rem;
    a {
      display: flex;
      align-items: center;
      margin-right: $spacer-2;
      text-decoration: none;
    }
    a:link,
    a:visited {
      @include body(true, var(--roasthof-black));
    }

    img {
      transform: rotate(270deg);
    }
  }

  .link-top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $spacer-1;
    margin: 3rem;
    @include body(true, var(--roasthof-blue));

    a {
      display: flex;
      align-items: center;
      margin-right: $spacer-1;
    }

    a:first-child {
      margin-left: $spacer-1;
    }

    a:link,
    a:visited {
      @include body(true, var(--roasthof-black));
    }

    img {
      transform: rotate(180deg);
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}

.apartment-container-miniature {
  @include colors;
  margin-top: 5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    margin-right: $spacer-2;
    text-decoration: none;
    cursor: pointer;
  }

  .szh-accordion {
    width: 20rem;
    margin: $spacer-2 0 6rem 0;
    align-self: center;
  }

  h2 {
    @include subtitle(true, var(--roasthof-blue));
  }

  .apartment-subtitle {
    @include subtitle(true, var(--roasthof-heavy-gray));
  }

  .apartment-description {
    width: 80%;
    text-align: left;
    line-height: 30px;
    margin-bottom: 3rem;
  }

  .apartments-box {
    margin-bottom: 3rem;
  }

  .apartment-miniature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-gallery {
      width: 90%;
    }

    .cover-img {
      background-color: var(--roasthof-heavy-gray);
      height: 13rem;
      width: 100%;
    }

    .image-gallery-bullets .image-gallery-bullet.active {
      background-color: var(--roasthof-blue);
      border: none;
    }
    .image-gallery-icon {
      color: var(--roasthof-blue);
    }
    .image-gallery-slide-wrapper,
    .fullscreen,
    .image-gallery-swipe,
    .image-gallery-slides,
    .image-gallery-center,
    .image-gallery-center img {
      height: 100%;
      background-color: var(--roasthof-white);
    }
  }

  .services-cross {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 $spacer-1;
    margin-bottom: 3rem;
    a {
      margin: $spacer-05 0;
      display: flex;
      align-items: center;
    }

    a:link,
    a:visited {
      @include body(true, var(--roasthof-black));
    }

    img {
      transform: rotate(270deg);
    }
  }

  .link-top {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 12rem;
    padding: $spacer-05 $spacer-1 0 $spacer-1;
    margin: 3rem 0;
    @include body(true, var(--roasthof-blue));

    a {
      display: flex;
      align-items: center;
      margin: $spacer-05 0;
    }

    a:link,
    a:visited {
      @include body(true, var(--roasthof-black));
    }

    img {
      transform: rotate(180deg);
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}

.apartments-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .apartment {
    margin: $spacer-05 0;
    padding: $spacer-025;
    background-color: var(--roasthof-heavy-gray);
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .szh-accordion {
    margin: $spacer-2 0;
  }
}

#children {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 3rem;

  .children-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      width: 480px;
      height: 270px;
      margin: 0 3rem;
    }
    div {
      width: 50%;
    }
  }
}

#general-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  .house-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
}

.house-section-miniature,
.children-section-miniature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 $spacer-1;

  img {
    width: 90%;
  }

}

.tablet .szh-accordion {
  margin: $spacer-2 0;
  width: 80%;
}

.booking-btn {
  @include body(true, var(--roasthof-white));
  letter-spacing: 0.75px;
  line-height: 1.333;
  background-color: var(--roasthof-blue);
  border-radius: 15px;
  border: none;
  padding: $spacer-05;
  margin-left: $spacer-05;
  cursor: pointer;
}
