@import "./../../style/typography";
@import "./../../style/colors";
@import "./../../style/variables";

.near {
  @include colors;
  margin-top: 15rem;
  h2 {
    @include subtitle(true, var(--roasthof-blue));
    text-align: center;
    margin-top: 5rem;
  }
  h3 {
    @include body(true, var(--roasthof-black));
    text-align: center;
    font-size: 20px;
  }

  .subtitle {
    margin-top: 5rem;
  }

  .map-title {
    @include subtitle(true, var(--roasthof-blue));
  }

  .map-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: $spacer-2;
  }

  .near-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: auto;
    margin-top: 5rem;
    gap: 4rem;

    .css-1ri6ub7-MuiPaper-root-MuiCard-root,
    .css-qpjsw6-MuiPaper-root-MuiCard-root {
      @include colors;
      box-shadow: #00000000 0px 2px 1px -1px, #00000000 0px 1px 1px 0px, #00000000 0px 1px 3px 0px;
      border: 2px solid var(--roasthof-white) !important;
      height: 30rem
    }
  }

  .pinnisalm-box {
    @include colors;
    background-color: var(--roasthof-blue);
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 40%;
    }
    &__text {
      @include body(false, var(--roasthof-white));
      padding: 0 $spacer-2;
      line-height: 30px;
    }
  }

  .pinnisalm-mobile {
    @include colors;
    background-color: var(--roasthof-blue);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 90%;
      padding-top: $spacer-1;
    }
    .pinnisalm-box__text {
      @include body(false, var(--roasthof-white));
      padding: 0 $spacer-1;
      line-height: 30px;
    }
  }

  .seasons {
    @include colors;
    width: 100%;
    background-color: var(--roasthof-light-gray);
    display: flex;
    justify-content: space-around;
    padding-top: 3rem;
    margin: 5rem 0;
gap: $spacer-1;
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40%;
      p {
        @include body(false, var(--roasthof-black));
        text-align: center;
        padding: 0 2rem;
      }
      img {
        width: 50vh;
        border-radius: 15px;
      }
    }
  }

  .seasons-miniature {
    @include colors;
    margin-top: 7rem;
    width: 100%;
    background-color: var(--roasthof-light-gray);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        @include body(false, var(--roasthof-heavy-gray));
        text-align: center;
        padding: 0 $spacer-1;
      }
      img {
        width: 80%;
        border-radius: 15px;
      }
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}

.mobile {
  margin-top: 3rem;
}

.MuiCardContent-root p {
  @include body(false, var(--roasthof-black));
}

.css-h93ljk-MuiTypography-root {
  @include body(true, var(--roasthof-blue));
  font-weight: bold !important;
}
