@import "../../style/colors";
@import "../../style/typography";
@import "../../style/variables";

$spacer-5rem: 5rem;
$spacer-10rem: 10rem;
$spacer-35rem: 35rem;
$spacer-60rem: 60rem;

.footer-component {
  display: flex;
  flex-direction: column;
  margin-top: $spacer-5rem;
  .footer-map {
    @include colors;
    height: $spacer-35rem;
    width: 100%;
    background-color: var(--roasthof-light-gray);
    display: flex;
    align-items: center;
    justify-content: space-around;
    &__text {
      display: flex;
      flex-direction: column;

      a {
        color: var(--roasthof-blcak);
        text-decoration: none;
        cursor: pointer;
      }

      p:first-child {
        @include subtitle(true);
      }

      p:last-child {
        width: 70%;
        line-height: 30px;
      }

      button {
        margin-top: $spacer-2;
        background-color: var(--roasthof-blue);
        span {
          @include body(false);
          color: var(--roasthof-white);
        }
      }
    }

    &__text-icons {
      margin-top: $spacer-2;
      img {
        margin-right: $spacer-125;
        cursor: pointer;
      }
    }
  }
  .footer-address p {
    @include body(false);
    line-height: 0px;
    &:first-child {
      @include body(false);
    }
  }
  .footer-bar {
    @include colors;
    height: $spacer-10rem;
    width: 100%;
    background-color: var(--roasthof-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__logo {
      width: $spacer-10rem;
    }
    p,
    a {
      @include caption(false, var(--roasthof-white));
    }
  }
}

.footer-component-mobile {
    display: flex;
    flex-direction: column;
    margin-top: $spacer-5rem;
    .footer-map-mobile {
      @include colors;
      height: $spacer-60rem;
      width: 100%;
      background-color: var(--roasthof-light-gray);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      &__text {
        display: flex;
        flex-direction: column;
        margin-left: $spacer-2;
        margin-bottom: -$spacer-5rem;
  
        a {
          color: var(--roasthof-blcak);
          text-decoration: none;
          cursor: pointer;
        }
  
        p:first-child {
          @include subtitle(true);
        }

        p:nth-child(2) {
            width: 70%;
          }
  
        p:last-child {
          width: 70%;
          line-height: 30px;
        }
  
        button {
          margin-top: $spacer-2;
          background-color: var(--roasthof-blue);
          span {
            @include body();
            color: var(--roasthof-white);
          }
        }
      }
  
      &__text-icons {
        margin-top: $spacer-2;
        img {
          margin-right: $spacer-125;
          cursor: pointer;
        }
      }
    }
    .footer-mobile-address p {
      @include body(false);
      line-height: 0px;
      &:first-child {
        @include body(false);
      }
    }
    .footer-bar-mobile {
      @include colors;
      height: $spacer-10rem;
      width: 100%;
      background-color: var(--roasthof-blue);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__logo {
        width: $spacer-10rem;
      }
      p,
      a {
        @include caption(false, var(--roasthof-white));
      }
    }
  }
