@import "../../../../style/variables";
@import "../../../../style/typography";
@import "../../../../style/colors";

$spacer-10px: 10px;
$spacer-15px: 15px;
$spacer-20px: 20px;
$spacer-30px: 30px;

$spacer-30rem: 30rem;

$spacer-80perc: 80%;

.card-responsive {
  @include colors;
  margin-top: $spacer-2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text {
    width: $spacer-80perc;
    padding: 0 $spacer-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: $spacer-30px;
    &--title {
      @include subtitle(true);
      align-self: flex-end;
    }
  }

  .card-responsive-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    border-radius: $spacer-15px;
    margin-bottom: $spacer-2;
  }

  .blue-rect {
    width: $spacer-05;
    height: $spacer-2;
    border-radius: $spacer-10px;
    background-color: var(--roasthof-blue);
    align-self: flex-start;
    margin-left: $spacer-2;
  }

  .black-rect {
    width: $spacer-05;
    height: $spacer-2;
    border-radius: $spacer-10px;
    background-color: var(--roasthof-heavy-gray);
    align-self: flex-end;
    margin-right: $spacer-2;
    margin-top: $spacer-2;
  }

  .card-responsive-button {
    background-color: var(--roasthof-blue);
    margin-top: $spacer-2;
    a {
      @include body(false, var(--roasthof-white));
    }
  }

  .tablet {
    margin-top: $spacer-30rem;
  }
}
