@import "../../style/colors";
@import "../../style/typography";
@import "../../style/variables";

.contact {
  margin-top: 15rem;
  h2 {
    @include colors;
    color: var(--roasthof-blue);
    text-align: center;
    margin-bottom: 5rem;
  }

  .box {
    display: block;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: 5rem;

    p {
      @include colors;
      @include body(false, var(--roasthof-heavy-gray));
      width: 80%;
      &:first-child {
        @include subtitle(true, var(--roasthof-blue));
      }
    }

    &__img {
      width: 500px;
      border-radius: 15px;
    }

    &--reverse {
      @include colors;
      display: block;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30rem;
      background-color: var(--roasthof-light-gray);

      p {
        @include colors;
        @include body(false, var(--roasthof-heavy-gray));
        width: 80%;
        text-align: right;
        &:first-child {
          @include subtitle(true, var(--roasthof-blue));
        }
      }

      .box-text {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }

      .box-container {
        display: block;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
      }
    }
  }

  .contact-form-title {
    margin: 5rem 0 3rem 0;
  }

  input {
    @include colors;
    height: $spacer-2;
    width: 15rem;
    border: 1px solid #00000000;
    border-radius: 0;
    border-bottom: 1px solid var(--roasthof-black);
    color: var(--roasthof-black);
    padding: $spacer-05 0;
    &::placeholder {
      @include body(false, var(--roasthof-black));
    }
  }

  .msg {
    background-color: var(--roasthof-light-gray);
    margin-top: $spacer-05;
    height: 7rem;
  }

  .submit-btn {
    cursor: pointer;
    width: 10rem;
    border-radius: 15px;
    padding: $spacer-025 $spacer-1;
    text-decoration: none;
    margin-top: $spacer-2;
    margin-bottom: 4rem;
    background-color: var(--roasthof-blue);
    color: var(--roasthof-white);

    &:hover {
      background-color: var(--roasthof-white);
      color: var(--roasthof-blue);
      border: 1px solid var(--roasthof-blue);
    }
    &:disabled {
      cursor: not-allowed;
      border: 1px solid var(--roasthof-light-gray);
      background-color: var(--roasthof-light-gray);
      color: var(--roasthof-heavy-gray);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      input {
        width: 100%;
        &:first-child {
          margin-right: $spacer-05;
        }
      }
    }

    .msg {
      margin-top: $spacer-1;
      width: 50%;
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }

  .contact-link {
    display: flex
  }
}

.contact-miniature {
  @include colors;
  margin-top: 7rem;
  h2 {
    color: var(--roasthof-blue);
    text-align: center;
    padding: 0 $spacer-15;
  }
  .box-miniature {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .box__img {
      width: 90%;
      border-radius: 15px;
    }
  }
  .box-container,
  .box-miniature div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .box-text, .box-miniature div p {
    @include body;
    width: 90%;
    text-align: left;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      input {
        width: 100%;
        margin: $spacer-025 0
      }
    }

    input {
      @include colors;
      height: $spacer-2;
      width: 15rem;
      border: 1px solid #00000000;
      border-radius: 0;
      border-bottom: 1px solid var(--roasthof-black);
      color: var(--roasthof-black);
      padding: $spacer-05 0;
      &::placeholder {
        @include body(false, var(--roasthof-black));
      }
    }

    .msg {
      margin-top: $spacer-1;
      width: 80%;
    }
    
    .submit-btn {
      cursor: pointer;
      width: 10rem;
      border-radius: 15px;
      padding: $spacer-025 $spacer-1;
      text-decoration: none;
      margin-top: $spacer-2;
      margin-bottom: 4rem;
      background-color: var(--roasthof-blue);
      color: var(--roasthof-white);
  
      &:hover {
        background-color: var(--roasthof-white);
        color: var(--roasthof-blue);
        border: 1px solid var(--roasthof-blue);
      }
      &:disabled {
        cursor: not-allowed;
        border: 1px solid var(--roasthof-light-gray);
        background-color: var(--roasthof-light-gray);
        color: var(--roasthof-heavy-gray);
      }
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }

  .contact-link {
    display: flex;
    flex-direction: row !important
  }
}

.subtitle {
  @include colors;
  @include subtitle(true, var(--roasthof-blue))
}