@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,400;0,500;0,700;0,1000;1,200;1,400&display=swap');


$font-family-primary: 'Sofia Sans', Arial, sans-serif;
$font-family-secondary: 'Roboto Mono', monospace;

$font-size-default: 16px;
$letter-spacing: 0.75px;

$font-size-large: 1.575rem; // 22 - title
$font-size-medium: 1.75rem; // 18 - subtitle
$font-size-base: 1rem; // 16 - body
$font-size-small: 0.75rem; // 12 - caption

$line-height-large: 1;
$line-height-medium: 1.25;
$line-height-base: 1.333;
$line-height-small: 1.5;

@mixin common-typography($is-bold: false, $color: null) {
  @if ($is-bold) {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }

  @if ($color) {
    color: $color;
  }
}

@mixin title($is-bold: false, $color: null) {
  @include common-typography($is-bold, $color);

  font-size: $font-size-large;
  letter-spacing: $letter-spacing;
  line-height: $line-height-large;
}

@mixin subtitle($is-bold: false, $color: null) {
  @include common-typography($is-bold, $color);

  font-size: $font-size-medium;
  letter-spacing: $letter-spacing;
  line-height: $line-height-medium;
}

@mixin body($is-bold: false, $color: null) {
  @include common-typography($is-bold, $color);

  font-size: $font-size-base;
  letter-spacing: $letter-spacing;
  line-height: $line-height-base;
}

@mixin caption($is-bold: false, $color: null) {
  @include common-typography($is-bold, $color);

  font-size: $font-size-small;
  letter-spacing: $letter-spacing;
  line-height: $line-height-small;
}

