@import "../../../../style/colors";
@import "../../../../style/variables";
@import "../../../../style/typography";

$spacer-5em: 5em;

$spacer-25rem: 2.5rem;
$spacer-3rem: 3rem;
$spacer-4rem: 4rem;
$spacer-5rem: 5rem;
$spacer-8rem: 8rem;
$spacer-10rem: 10rem;
$spacer-12rem: 12rem;

$spacer-20px: 20px;

$z-index-value: 999;

$bck-color-sub-items: 4e5457;

.mobile-menu {
  @include colors;
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  &__header {
    background-color: var(--roasthof-blue);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: $spacer-4rem;
    z-index: $z-index-value;
    position: fixed;
    top: 0;
  }
  &__body {
    z-index: $z-index-value;
    background-color: var(--roasthof-heavy-gray);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__items {
    margin-top: $spacer-3rem;
    display: flex;
    margin-left: -$spacer-5em;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    width: $spacer-12rem;
    padding-left: $spacer-5rem;
    button {
      padding: $spacer-025 0;
    }
  }
  &__items-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
  }
  &__subitems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    background-color: $bck-color-sub-items;
    padding: 0 $spacer-1;
  }
  &__social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: $spacer-05;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 $spacer-05;
      }
    }
  }
  &__icon {
    width: $spacer-15;
    padding: 0 $spacer-125;
    &--circle {
      width: $spacer-25rem;
    }
  }
  &__logo {
    width: $spacer-8rem;
    margin-top: $spacer-5rem;
  }

  &__footer {
    width: 100%;
    position: fixed;
    bottom: $spacer-2;
  }

  .arrow-down {
    transform: rotate(90deg);
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }

  // OVERRIDES
  .cta-prenota {
    @include body (true, var(--roasthof-blue));
    background-color: var(--roasthof-white);
    border: 1px solid var(--roasthof-white);
    width: $spacer-8rem;
    height: $spacer-2;
    border-radius: 15px;
    margin-bottom: $spacer-1;
  }

  .language--mobile {
    position: fixed;
    z-index: $z-index-value;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    bottom: $spacer-10rem;
    .language-items {
      @include body(false, var(--roasthof-white));
      font-size: $spacer-20px;
      span {
        padding: 0 $spacer-05;
      }
    }
    .language-items--selected span:first-child {
      @include body(true, var(--roasthof-white));
      font-size: $spacer-20px;
      text-decoration: underline;
    }
  }

  .container & {
    overflow-y: hidden;
  }

  #root & {
    overflow-y: hidden;
  }

  .mobile-menu-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacer-05
  }
}
