@import "../../style/typography";
@import "../../style/variables";
@import "../../style/colors";

.modal-booking {
  @include colors;
  width: 90vw;
  height: 90vh;
  background-color: var(--roasthof-blue);
  position: fixed;
  z-index: 999;
  top: $spacer-2;
  left: 4rem;

  .modal-booking-container {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
    padding: $spacer-1;
    .icon-btn {
      background-color: #00000000;
      border: none;
      align-self: flex-end;
      cursor: pointer;
      img {
        width: $spacer-1;
        height: $spacer-1;
      }
    }
  }

  .modal-booking-group {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding: $spacer-1 0;

    .Dropdown-root {
      background-color: #00000000;
      border: 1px solid var(--roasthof-white);
      width: 40rem;
      height: $spacer-2;
      z-index: 999;
    }

    .Dropdown-placeholder {
      @include body(false, var(--roasthof-white));
      padding-top: $spacer-025;
    }

    .Dropdown-option {
      @include body(false, var(--roasthof-blue));
      padding-top: $spacer-025;
    }
    .Dropdown-menu {
      background-color: var(--roasthof-white);
    }
  }

  .modal-booking-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    input {
      height: $spacer-2;
      width: 30rem;
      border: none;
      background-color: var(--roasthof-blue);
      border-bottom: 1px solid var(--roasthof-white);
      color: var(--roasthof-white);
      &::placeholder {
        @include body(false, var(--roasthof-white));
      }
    }
    p {
      @include body(false, var(--roasthof-white));
    }
  }

  .submit-btn {
    cursor: pointer;
    border: 1px solid var(--roasthof-white);
    border-radius: 15px;
    padding: $spacer-025 $spacer-1;
    text-decoration: none;
    &:hover {
      background-color: var(--roasthof-white);
      color: var(--roasthof-blue);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  p {
    @include body(false, var(--roasthof-white));
  }

  .rooms {
    display: flex;
    p {
      @include body(false, var(--roasthof-white));
    }
  }

  .rooms-title {
    @include subtitle(false, var(--roasthof-white));
  }

  form {
    height: 80vh;
    overflow-y: scroll;
  }

  .room-group {
    padding: 0;
  }

  .rooms-title {
    margin-bottom: 0;
  }
}

.modal-booking-miniature {
  @include colors;
  width: 90vw;
  height: 90vh;
  background-color: var(--roasthof-blue);
  position: fixed;
  z-index: 999;
  top: $spacer-2;
  margin-left: $spacer-1;

  form {
    height: 80vh;
    overflow-y: scroll;
  }

  .modal-booking-container {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
    padding: $spacer-1;
    .icon-btn {
      background-color: #00000000;
      border: none;
      align-self: flex-end;
      img {
        width: $spacer-1;
        height: $spacer-1;
      }
    }
  }
  .modal-booking-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .Dropdown-root {
      background-color: #00000000;
      border: 1px solid var(--roasthof-white);
      height: $spacer-2;
      z-index: 999;
    }

    .Dropdown-placeholder {
      @include body(false, var(--roasthof-white));
      padding-top: $spacer-025;
    }

    .Dropdown-option {
      @include body(false, var(--roasthof-blue));
      padding-top: $spacer-025;
    }
    .Dropdown-menu {
      background-color: var(--roasthof-white);
    }
  }
  .modal-booking-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    input {
      height: $spacer-2;
      width: 15rem;
      border: 1px solid #00000000;
      background-color: var(--roasthof-blue);
      border-radius: 0;
      border-bottom: 1px solid var(--roasthof-white);
      color: var(--roasthof-white);
      padding: $spacer-05 0;
      &::placeholder {
        @include body(false, var(--roasthof-white));
      }
    }
    p {
      @include body(false, var(--roasthof-white));
    }
  }

  .room-group {
    align-items: flex-start;
    padding-left: 2rem;
    width: 80%;
  }

  .submit-btn {
    cursor: pointer;
    border: 1px solid var(--roasthof-white);
    border-radius: 15px;
    padding: $spacer-025 $spacer-1;
    text-decoration: none;
    margin-top: $spacer-2;
    margin-bottom: 4rem;

    &:hover {
      background-color: var(--roasthof-white);
      color: var(--roasthof-blue);
    }
    &:disabled {
      cursor: not-allowed;
      border: 1px solid var(--roasthof-light-gray);
      background-color: var(--roasthof-light-gray);
      color: var(--roasthof-heavy-gray);
    }
  }

  p {
    @include body(false, var(--roasthof-white));
  }

  .rooms {
    display: flex;
    align-items: center;
    p {
      @include body(false, var(--roasthof-white));
    }
  }

  .rooms-title {
    @include subtitle(false, var(--roasthof-white));
  }
}

.required-label {
  font-size: 10px !important;
  color: #000000 !important;
}
