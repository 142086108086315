@import "./../../style/typography";
@import "./../../style/colors";
@import "./../../style/variables";

$font-size-custom: 45px;

$spacer-15px: 15px;

$spacer-3rem: 3rem;
$spacer-4rem: 4rem;
$spacer-5rem: 5rem;
$spacer-6rem: 6rem;
$spacer-7rem: 7rem;
$spacer-10rem: 10rem;
$spacer-15rem: 15rem;
$spacer-20rem: 20rem;
$spacer-25rem: 25rem;
$spacer-30rem: 30rem;
$spacer-35rem: 35rem;
$spacer-40rem: 40rem;
$spacer-55rem: 55rem;

.seasons-page {
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  h2 {
    @include subtitle(true);
    text-align: center;
  }

  &__description {
    @include body;
    line-height: 30px;
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .super-card {
    @include colors;
    margin: $spacer-2 0;
    &__title {
      @include subtitle(true, var(--roasthof-blue));
      text-align: center;
    }
    &__box {
      @include colors;
      height: $spacer-30rem;
      width: 100%;
      background-color: var(--roasthof-blue);
      margin-top: $spacer-5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        height: 100%;
      }
    }
    &__text {
      @include body(false, var(--roasthof-white));
      line-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90rem;
      padding-left: 1rem;
      p:first-child {
        @include subtitle(true);
      }
      button {
        span {
          color: var(--roasthof-blue);
          font-size: 20px;
        }
      }
    }
    &__logo {
      height: $spacer-10rem !important;
      border-radius: 15px;
      margin-right: 3rem;
    }

    .reverse {
      display: flex;
      justify-content: flex-end;

      .super-card__text {
        padding-right: $spacer-1;
        width: 70%;
        p {
          margin: 0;
        }
      }
    }

    .normal {
      display: flex;
      justify-content: flex-start;

      .super-card__text {
        padding-right: $spacer-1;
        width: 70%;
        p {
          margin: 0;
        }
      }
    }
  }

  .super-card-mobile {
    display: flex;
    flex-direction: column;
    .super-card__title {
      @include subtitle(true);
      text-align: right;
      width: 70%;
      margin-left: $spacer-4rem;
    }

    .super-card__box {
      display: flex;
      flex-direction: column;
      margin-top: 25rem;
      margin-bottom: 3rem;

      img {
        width: 100%;
        margin-bottom: 2rem;
      }
      img:last-child {
        width: $spacer-6rem;
        border-radius: 15px;
      }
    }

    .first {
      margin-top: 2rem;
    }

    .super-card__text {
      @include colors;
      width: 100%;
      background-color: var(--roasthof-blue);
      color: var(--roasthof-white);
      line-height: 30px;
      padding-left: 0;
      padding-bottom: 8rem;
      p {
        width: 80%;
        padding-left: $spacer-2;
      }

      button {
        margin-left: $spacer-2;
        a {
          font-size: 20px;
        }
      }
    }
  }
  .second {
    margin-top: 25rem;
    .super-card__box:first-child {
      margin-top: 2rem;
    }
  }

  .no-margin-top {
    margin-top: 0 !important;
  }

  .last {
    margin-bottom: 40rem !important;
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 4rem;
  }
}
