@import "./style/typography";
@import "./style/colors";
@import "./style/variables";

$spacer-3rem: 3rem;
$spacer-5rem: 5rem;

html {
  font: {
    family: $font-family-primary;
    size: $font-size-default;
  }
}

body {
  margin: 0;
  padding: 0;

  .banner {
    &__social {
      @include colors;
      height: $spacer-5rem;
      width: $spacer-3rem;
      background-color: var(--roasthof-heavy-gray);
      position: fixed;
      border-radius: 15px;
      right: $spacer-2;
      bottom: $spacer-2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      padding: $spacer-1 0;
      z-index: 999;
    }

    &__social-icon {
      width: $spacer-2;
      padding-bottom: $spacer-1;
    }
  }

  .banner-cta {
    cursor: pointer;

    &--text {
      cursor: pointer;
      @include colors;
      @include body(true, var(--roasthof-white));
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-color: var(--roasthof-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      position: fixed;
      right: $spacer-1;
      bottom: 10rem;
      border: 5px solid var(--roasthof-heavy-gray);
      z-index: 999;
      &:link,
      &:visited {
        @include body(true, var(--roasthof-white));
      }
    }
  }

  .second {
    cursor: pointer;
    position: fixed;
    right: $spacer-1;
    bottom: 17rem;
    border: 5px solid var(--roasthof-heavy-gray);
    z-index: 999;
    background-color: var(--roasthof-white);
    color: var(--roasthof-heavy-gray);
  }

  .fixed {
    cursor: pointer;
    position: fixed;
    right: $spacer-1;
    bottom: 10rem;
    border: 5px solid var(--roasthof-heavy-gray);
    z-index: 999;
    background-color: var(--roasthof-blue);
    color: var(--roasthof-white);
  }
}
