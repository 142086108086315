@import "../../../style/colors";
@import "../../../style/typography";
@import "../../../style/variables";

$spacer-4rem: 4rem;
$spacer-8rem: 8rem;
$spacer-10rem: 10rem;
$spacer-12rem: 12rem;

$spacer-10px: 10px;
$spacer-15px: 15px;
$spacer-20px: 20px;
$spacer-30px: 30px;
$spacer-500px: 500px;

$spacer-80perc: 80%;

.card {
  @include colors;
  display: flex;
  flex-direction: column;
  padding: 0 $spacer-12rem;
  margin-top: $spacer-8rem;

  &--right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    padding: $spacer-2 0;
    .card__text {
      display: flex;
      flex-direction: column;

      &--title {
        @include subtitle(true);
        align-self: flex-end;
      }

      &--description {
        @include body;
        line-height: $spacer-30px;
        width: $spacer-80perc;
        text-align: left;
        padding: $spacer-2 0 0 $spacer-4rem;
      }
    }

    .blue-rect {
      width: $spacer-2;
      height: $spacer-8rem;
      border-radius: $spacer-10px;
      background-color: var(--roasthof-blue);
      margin-right: $spacer-1;
    }

    .black-rect {
      width: $spacer-2;
      height: $spacer-8rem;
      border-radius: $spacer-10px;
      background-color: var(--roasthof-heavy-gray);
      margin-left: $spacer-1;
      align-self: flex-end;
    }
  }
  &--left {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $spacer-2 0;
    .card__text {
      display: flex;
      flex-direction: column;

      &--title {
        @include subtitle(true);
        align-self: flex-start;
      }

      &--description {
        @include body;
        line-height: $spacer-30px;
        width: $spacer-80perc;
        text-align: left;
        padding: $spacer-2 0 0 $spacer-4rem;
      }
    }
    .blue-rect {
      width: $spacer-2;
      height: $spacer-8rem;
      border-radius: $spacer-10px;
      background-color: var(--roasthof-blue);
      margin-left: $spacer-1;
      align-self: flex-end;
    }

    .black-rect {
      width: $spacer-2;
      height: $spacer-8rem;
      border-radius: $spacer-10px;
      background-color: var(--roasthof-heavy-gray);
      margin-right: $spacer-1;
    }
  }
  img {
    width: $spacer-500px;
    height: 100%;
    border-radius: $spacer-15px;
  }

  &:first-child {
    margin-top: $spacer-10rem;
  }

  .card-button {
    background-color: var(--roasthof-blue);
    margin: $spacer-2 0 0 $spacer-4rem;
    a {
      @include body(false, var(--roasthof-white));
      font-size: $spacer-20px;
    }
  }
}
