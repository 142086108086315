@import '../../../style/typography';
@import '../../../style/colors';

.chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  }
  
  .szh-accordion {
    @include colors;
    width: 50rem;
    border-bottom: 1px solid var(--roasthof-heavy-gray);
    &__item {
      border-top: 1px solid var(--roasthof-heavy-gray);
  
      &-btn {
        @include body(false);
        font-family: $font-family-primary;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 1rem;
        font-size: 1rem;
        text-align: left;
        color: var(--roasthof-heavy-gray);
        background-color: transparent;
        border: none;
        &:hover {
          background-color: var(--roasthof-light-gray);
        }
      }
  
      &-content {
        @include body();
        transition: height 0.25s cubic-bezier(0, 0, 0, 1);
      }
  
      &-panel {
        padding: 1rem;
      }
  
      &--expanded {
        .szh-accordion__item-btn {
          font-family: $font-family-primary;
          @include body(true, var(--roasthof-blue));
          background-color: var(--roasthof-white);
        }
        .chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }