@import '../../../../style/typography';
@import '../../../../style/variables';
@import '../../../../style/colors';

$spacer-5rem: 5rem;
$spacer-9rem: 9rem;
$spacer-14rem: 14rem;

$width-menu: 92%;

.menu {
  @include colors;
  background-color: var(--roasthof-heavy-gray);
  height: $spacer-9rem;
  width: $width-menu;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacer-5rem;
  z-index: 999;

  &--active {
    font-weight: bold;
    text-decoration: underline
  }


.language--desktop {
  @include colors;
  position: fixed;
  top: $spacer-1;
  display: flex;
  width: $spacer-5rem;
  right: $spacer-14rem;
  cursor: pointer;
  .language-items {
    @include body(false, var(--roasthof-white));
    span {
      padding: 0 $spacer-05;
    }
    &--selected span:first-child {
      @include body(true, var(--roasthof-white));
      text-decoration: underline;
    }
  }
}
}
