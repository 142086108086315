@import "../../../style/typography";
@import "../../../style/colors";
@import "../../../style/variables";

$spacer-225rem: 2.25rem;
$spacer-10rem: 10rem;
$spacer-12rem: 12rem;

$spacer-10px: 10px;
$spacer-20px: 20px;

.button,
.button a {
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: #00000000;
  @include body(false, var(--roasthof-white));
  font-size: $spacer-20px;
  .img img {
    width: $spacer-12rem;
  }
}
.button--selected a {
  @include body(false, var(--roasthof-white));
  font-size: $spacer-20px;
  text-decoration: underline;
}

.button--outline a {
  @include body(false, var(--roasthof-white));
  font-size: $spacer-20px;
  text-decoration: underline;
  border: 1px solid var(--roasthof-blue);
  border-radius: $spacer-10px;
  background-color: var(--roasthof-blue);
  width: 100%
}

.button--fill {
  height: $spacer-225rem;
  width: $spacer-12rem;
  border-radius: $spacer-10px;
  background-color: var(--roasthof-white);
  a {
    @include body(false, var(--roasthof-heavy-gray));
    font-size: $spacer-20px;
  }
}
