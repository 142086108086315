@import "./../../style/typography";
@import "./../../style/colors";
@import "./../../style/variables";

$font-size-custom: 45px;

$spacer-15px: 15px;
$spacer-3rem: 3rem;
$spacer-4rem: 4rem;
$spacer-5rem: 5rem;
$spacer-6rem: 6rem;
$spacer-7rem: 7rem;
$spacer-10rem: 10rem;
$spacer-15rem: 15rem;
$spacer-20rem: 20rem;
$spacer-25rem: 25rem;
$spacer-30rem: 30rem;
$spacer-35rem: 35rem;
$spacer-40rem: 40rem;
$spacer-55rem: 55rem;

.page-container {
  .header {
    @include colors;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: $font-size-custom;
  }

  .body {
    .banner {
      &__image {
        width: 100%;
      }

      &--mobile {
        right: $spacer-15px;
        bottom: $spacer-15px;
      }
    }

    .intro {
      @include body();
      text-align: center;
      width: 50%;
      margin: $spacer-6rem auto $spacer-10rem;
      p {
        line-height: 30px;
      }

      &--mobile {
        @include colors;
        @include body(true);
        font-size: 40px;
        line-height: 30px;
        padding: 0 $spacer-3rem;

        div p:first-child {
          color: var(--roasthof-heavy-gray);
          text-align: right;
        }

        div p:last-child {
          color: var(--roasthof-blue);
        }

        h2 {
          @include body(true);
          font-size: 22px;
        }
      }
    }

    .intro-img {
      @include colors;
      background-color: var(--roasthof-light-gray);
      width: 100%;
      height: $spacer-55rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $spacer-3rem;
      
      img {
        width: 80%;
        border-radius: $spacer-15px;
        margin-bottom: $spacer-3rem;
      }

      p {
        @include body();
        padding: 0 $spacer-3rem;
        margin: $spacer-15px 0;
        line-height: 30px;
      }
    }

    .banner-welcome {
      @include colors;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: var(--roasthof-light-gray);
      height: $spacer-25rem;

      img {
        width: $spacer-30rem;
        border-radius: $spacer-15px;
      }

      div {
        p {
          font-size: 60px;
          margin: 0;
          padding: 0;
        }

        &:nth-child(1) {
          @include body(true, var(--roasthof-blue));
          padding-right: $spacer-10rem;
        }

        &:nth-child(2) {
          @include body(false, var(--roasthof-heavy-gray));
          margin-left: -$spacer-30rem;
          p {
            padding-bottom: $spacer-3rem;
          }
        }
      }
    }

    .super-card {
      margin: $spacer-5rem 0;

      &__title {
        @include subtitle(true);
        text-align: center;
      }

      &__box {
        @include colors;
        height: $spacer-30rem;
        width: 100%;
        background-color: var(--roasthof-blue);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img:first-child {
          height: 100%;
        }
      }

      &__text {
        @include body(false, var(--roasthof-white));
        line-height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: $spacer-30rem;
        padding-left: $spacer-3rem;

        p:first-child {
          @include subtitle(true);
        }

        button {
          span {
            color: var(--roasthof-blue);
            font-size: 20px;
          }
        }
      }

      &__logo {
        height: $spacer-10rem;
        border-radius: $spacer-15px;
        margin-left: $spacer-5rem;
      }
    }

    .super-card-mobile {
      &__title {
        @include subtitle(true);
        text-align: right;
        width: 70%;
        margin-left: $spacer-4rem;
      }

      &__box {
        display: flex;
        flex-direction: column;

        img {
          width: 100%;
        }

        img:last-child {
          width: $spacer-6rem;
          border-radius: $spacer-15px;
        }
      }

      &__text {
        @include colors;
        width: 100%;
        height: $spacer-30rem;
        background-color: var(--roasthof-blue);
        color: var(--roasthof-white);
        line-height: 30px;

        p:first-child {
          @include subtitle(true);
          padding: 0 $spacer-3rem;
        }

        p:nth-child(2) {
          padding: 0 $spacer-3rem;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 $spacer-3rem;

          button {
            span {
              @include body();
              color: var(--roasthof-blue);
            }
          }
        }
      }
    }

    .seasons {
      @include colors;
      width: 100%;
      height: $spacer-40rem;
      background-color: var(--roasthof-light-gray);
      display: flex;
      justify-content: space-around;

      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          @include subtitle(true, var(--roasthof-heavy-gray));
          text-align: center;
        }

        img {
          height: $spacer-25rem;
          width: 35rem;
          border-radius: $spacer-15px;
        }

        button {
          margin-top: $spacer-2;
          background-color: var(--roasthof-blue);

          a {
            font-size: 20px;
            color: var(--roasthof-white);
          }
        }
      }
    }

    .seasons-miniature {
      @include colors;
      width: 100%;
      height: 100%;
      padding: $spacer-2 0;
      background-color: var(--roasthof-light-gray);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacer-05 0;

        p {
          @include subtitle(true, var(--roasthof-heavy-gray));
          text-align: center;
        }

        img {
          width: 80%;
          border-radius: $spacer-15px;
        }

        button {
          margin-top: $spacer-2;
          background-color: var(--roasthof-blue);

          span {
            @include body();
            color: var(--roasthof-white);
          }

          a {
            color: var(--roasthof-white);
          }
        }
      }
    }

    .accordion {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        @include colors;
        color: var(--roasthof-blue);
      }

      &--mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
          @include colors;
          color: var(--roasthof-blue);
        }

        .szh-accordion {
          width: $spacer-20rem;
        }
      }
    }
  }

  .photo-copyright {
    @include caption;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: $spacer-4rem;
  }
}
